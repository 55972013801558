<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="modal fade" id="modal-form-liquidacion-export">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Reporte De Liquidaciones Internas
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label for="fecha_ini" class="col-md-5">Fecha Inicial</label>
              <input
                type="date"
                id="fecha_ini"
                v-model="filtros.fecha_ini"
                class="form-control form-control-sm col-md-7"
                @input="validaFechasForm()"
              />
            </div>
            <div class="form-group row">
              <label for="fecha_fin" class="col-md-5">Fecha Fin</label>
              <input
                type="date"
                id="fecha_fin"
                v-model="filtros.fecha_fin"
                class="form-control form-control-sm col-md-7"
                @input="validaFechasForm()"
              />
            </div>
            <div class="form-group row">
              <label for="empresa_id" class="col-md-5">Estado</label>
              <select
                id="estado"
                v-model="filtros.estado"
                class="form-control form-control-sm p-0 col-md-7"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in listasForms.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
            </div>
            <div class="form-group row">
              <label for="empresa_id" class="col-md-5">Estado Proceso</label>
              <select
                id="estado"
                v-model="filtros.estado_proceso"
                class="form-control form-control-sm p-0 col-md-7"
                :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                label="descripcion"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in listasForms.estadosProceso"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
            </div>
            <div class="form-group row">
              <label for="empresa_id" class="col-md-5">Empresa</label>
              <v-select
                class="form-control form-control-sm p-0 col-md-7"
                :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                v-model="filtros.empresas"
                placeholder="Empresas..."
                label="razon_social"
                :options="listasForms.empresas"
                :filterable="false"
                @change="$parent.buscarEmpresas"
                @input="selectEmpresa()"
              >
              </v-select>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarReporte()"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "CsLiquidacionExport", //llegada tambien
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      id: null,
      modalTitle: null,
      modalData: [],
      empresa: {},
      filtros: {
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        estado: null,
        estado_proceso: null,
      },
      liquidaciones: {},
      listasForms: {
        estados: [],
        empresas: [],
        estadosProceso: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    resetFilter() {
      this.filtros = {
        empresas: null,
        estado: null,
        estado_proceso: null,
      };
    },

    selectEmpresa() {
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
    },

    async getEmpresas() {
      await axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [4],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },

    async getEstados() {
      await axios.get("/api/lista/100").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getEstadosProceso() {
      await axios.get("/api/lista/101").then((response) => {
        this.listasForms.estadosProceso = response.data;
      });
    },

    //MANDA A LLAMAR LA FUNCION EXPORT EN EL BACK
    generarReporte() {
      this.cargando = true;

      // Se ajustan los filtros

      this.filtros.empresa_id = this.filtros.empresas
        ? this.filtros.empresas.id
        : null;

      axios({
        method: "POST",
        url: "/api/cs/liquidacionesInternos/exportReporte",
        data: this.filtros,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          if (data.error) {
            this.$swal({
              icon: "error",
              title: "No hay registros dentro del rango seleccionado",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.$swal({
              icon: "success",
              title: data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            location.href = data.url;
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    validaFechasForm() {
      if (this.filtros.fecha_ini && this.filtros.fecha_fin) {
        const fecha_menor = new Date(this.filtros.fecha_ini);
        const fecha_mayor = new Date(this.filtros.fecha_fin);

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha fin..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }

      if (this.filtros.fecha_ini && this.filtros.fecha_fin) {
        let fecha_menor = new Date(this.filtros.fecha_ini).getTime();
        let fecha_mayor = new Date(this.filtros.fecha_fin).getTime();
        let diff = fecha_mayor - fecha_menor;
        let fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.filtros.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 30 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
  },

  beforeMount() {
    this.getEmpresas();
    this.getEstados();
    this.getEstadosProceso();
  },
};
</script>
